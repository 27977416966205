<template>
	<svg width="597" height="800" viewBox="0 280 597 600" fill="none" xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink" data-v-5aa2490e="">
		<g id="client mobile">
			<top-paths></top-paths>
			<bee-hive-game></bee-hive-game>
			<mid-path1></mid-path1>
			<bhfl></bhfl>
			<mid-path2></mid-path2>
			<bhu></bhu>
			<mid-path3></mid-path3>
			<billing></billing>
			<blog></blog>
			<books></books>
			<mid-path4></mid-path4>
			<community-resources></community-resources>
			<mid-path5></mid-path5>
			<coping-skills></coping-skills>
			<contact-practitioner></contact-practitioner>
			<mid-path6></mid-path6>
			<emergency></emergency>
			<events></events>
			<faqs></faqs>
			<mid-path7></mid-path7>
			<forms></forms>
			<first-responders></first-responders>
			<mid-path8></mid-path8>
			<insurance></insurance>
			<mid-path9></mid-path9>
			<messenger></messenger>
			<life-plan></life-plan>
			<journal></journal>
			<mid-path10></mid-path10>
			<online-store></online-store>
			<schedule></schedule>
			<path-to-licensure></path-to-licensure>
			<mid-path11></mid-path11>
			<social-media></social-media>
			<self-care></self-care>
			<social-media2></social-media2>
			<mid-path12></mid-path12>
			<wellness-centers></wellness-centers>
			<treatment-plan></treatment-plan>
			<dimentions></dimentions>
			<bottom-paths></bottom-paths>
		</g>
		<definitions></definitions>
	</svg>
</template>

<script>
import TopPaths from './ClientMenuSmallItems/TopPaths.vue';
import BeeHiveGame from './ClientMenuSmallItems/BeeHiveGame.vue';
import MidPath1 from './ClientMenuSmallItems/MidPath1.vue';
import Bhfl from './ClientMenuSmallItems/Bhfl.vue';
import MidPath2 from './ClientMenuSmallItems/MidPath2.vue';
import Bhu from './ClientMenuSmallItems/Bhu.vue';
import MidPath3 from './ClientMenuSmallItems/MidPath3.vue';
import Billing from './ClientMenuSmallItems/Billing.vue';
import Blog from './ClientMenuSmallItems/Blog.vue';
import Books from './ClientMenuSmallItems/Books.vue';
import MidPath4 from './ClientMenuSmallItems/MidPath4.vue';
import CommunityResources from './ClientMenuSmallItems/CommunityResources.vue';
import MidPath5 from './ClientMenuSmallItems/MidPath5.vue';
import CopingSkills from './ClientMenuSmallItems/CopingSkills.vue';
import ContactPractitioner from './ClientMenuSmallItems/ContactPractitioner.vue';
import MidPath6 from './ClientMenuSmallItems/MidPath6.vue';
import Emergency from './ClientMenuSmallItems/Emergency.vue';
import Events from './ClientMenuSmallItems/Events.vue';
import Faqs from './ClientMenuSmallItems/Faqs.vue';
import MidPath7 from './ClientMenuSmallItems/MidPath7.vue';
import Forms from './ClientMenuSmallItems/Forms.vue';
import FirstResponders from './ClientMenuSmallItems/FirstResponders.vue';
import MidPath8 from './ClientMenuSmallItems/MidPath8.vue';
import Insurance from './ClientMenuSmallItems/Insurance.vue';
import MidPath9 from './ClientMenuSmallItems/MidPath9.vue';
import Messenger from './ClientMenuSmallItems/Messenger.vue';
import LifePlan from './ClientMenuSmallItems/LifePlan.vue';
import Journal from './ClientMenuSmallItems/Journal.vue';
import MidPath10 from './ClientMenuSmallItems/MidPath10.vue';
import OnlineStore from './ClientMenuSmallItems/OnlineStore.vue';
import Schedule from './ClientMenuSmallItems/Schedule.vue';
import PathToLicensure from './ClientMenuSmallItems/PathToLicensure.vue';
import MidPath11 from './ClientMenuSmallItems/MidPath11.vue';
import SocialMedia from './ClientMenuSmallItems/SocialMedia.vue';
import SelfCare from './ClientMenuSmallItems/SelfCare.vue';
import SocialMedia2 from './ClientMenuSmallItems/SocialMedia2.vue';
import MidPath12 from './ClientMenuSmallItems/MidPath12.vue';
import WellnessCenters from './ClientMenuSmallItems/WellnessCenters.vue';
import TreatmentPlan from './ClientMenuSmallItems/TreatmentPlan.vue';
import Dimentions from './ClientMenuSmallItems/Dimentions.vue';
import BottomPaths from './ClientMenuSmallItems/BottomPaths.vue';
import Definitions from './ClientMenuSmallItems/Definitions.vue';

export default {
	components: {
		TopPaths,
		BeeHiveGame,
		MidPath1,
		Bhfl,
		MidPath2,
		Bhu,
		MidPath3,
		Billing,
		Blog,
		Books,
		MidPath4,
		CommunityResources,
		MidPath5,
		CopingSkills,
		ContactPractitioner,
		MidPath6,
		Emergency,
		Events,
		Faqs,
		MidPath7,
		Forms,
		FirstResponders,
		MidPath8,
		Insurance,
		MidPath9,
		Messenger,
		LifePlan,
		Journal,
		MidPath10,
		OnlineStore,
		Schedule,
		PathToLicensure,
		MidPath11,
		SocialMedia,
		SelfCare,
		SocialMedia2,
		MidPath12,
		WellnessCenters,
		TreatmentPlan,
		Dimentions,
		BottomPaths,
		Definitions,
	},
	setup() {
		return {};
	},
};
</script>